<template>
<action-button
    @click="downloadFile"
    :working="downloading"
    :left-icon="leftIcon">
    <slot />
</action-button>
</template>
<script>
import download from 'downloadjs'
import { common as backend } from '@/api'

export default {
    
    props: {
        path: String,
        fileName: String,
        leftIcon: String,
    },

    data: () => ({
        downloading: false
    }),

    methods: {
        downloadFile() {
            this.downloading = true
            backend.downloadFile('/v1' + this.path, ({data}) => {
                download(data, this.fileName, data.type);
                this.downloading = false
            }, () => {
                this.downloading = false
            })
        }
    }

}
</script>